import { useMultiAccountFeature } from "src/libraries/organisation/multi-accounts/hooks";

export const MultiAccountRestriction: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { hasMultiAccountFeature } = useMultiAccountFeature();

  if (!hasMultiAccountFeature) return null;

  return <>{children}</>;
};
