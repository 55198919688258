import { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import {
  Banner,
  BannerVariant,
  Box,
  ColorPreset,
  FontWeight,
  PlainButton,
  Separator,
  Space,
  Text,
  Visibility,
} from "@gocardless/flux-react";

import {
  useOrgSwitcherData,
  useSwitchOrganisation,
  useMultiAccountSessionStorage,
} from "src/libraries/organisation/multi-accounts/hooks";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useIsMerchantImpersonation } from "src/queries/user";

export const OrganisationBanner: React.FC = () => {
  const { sessionStorage, setSessionStorage } = useMultiAccountSessionStorage();

  const [isBannerDismissed, setIsBannerDismissed] = useState<boolean>(
    () => sessionStorage.isBannerDismissed
  );

  const { loggedInOrganisation } = useOrgSwitcherData();

  const {
    id: loggedInOrganisationId,
    name: loggedInOrganisationName,
    nickname: loggedInOrganisationNickname,
  } = loggedInOrganisation ?? {};

  const { submit } = useSwitchOrganisation({
    organisationId: sessionStorage.previousOrgId,
  });

  const { isViewingDifferentOrganisation } = useIsMerchantImpersonation();

  const { sendEvent } = useSegment();

  useEffect(() => {
    if (!loggedInOrganisationId) return;

    if (sessionStorage.currentOrgId !== loggedInOrganisationId) {
      setIsBannerDismissed(false);

      setSessionStorage({
        currentOrgId: loggedInOrganisationId,
        ...(isViewingDifferentOrganisation ? { isBannerDismissed: false } : {}),
      });
    }
  }, [
    loggedInOrganisationId,
    sessionStorage,
    setSessionStorage,
    isViewingDifferentOrganisation,
  ]);

  const handleUndo = () => {
    submit(sessionStorage.previousOrgId);

    sendEvent(TrackingEvent.MULTI_ACCOUNT_UNDO_CTA_CLICKED, {
      from_organisation_id: sessionStorage.currentOrgId,
      to_organisation_id: sessionStorage.previousOrgId,
    });
  };

  const handleClose = () => {
    setIsBannerDismissed(true);

    setSessionStorage({
      isBannerDismissed: true,
    });

    sendEvent(TrackingEvent.MULTI_ACCOUNT_ACCOUNT_BANNER_CLOSED);
  };

  if (isBannerDismissed || !sessionStorage.previousOrgId) {
    return null;
  }

  return (
    <Banner
      closeAction={{
        label: <Trans id="Close">Close</Trans>,
        onClose: handleClose,
      }}
      variant={BannerVariant.FullBleed}
    >
      <Box>
        <Text layout={["block", null, "inline-block"]}>
          <Trans>
            You are now logged in to{" "}
            <Text weight={FontWeight.SemiBold}>
              {loggedInOrganisationNickname || loggedInOrganisationName}
            </Text>
            .
          </Trans>
        </Text>
        {sessionStorage.previousOrgId && (
          <>
            <Visibility visible={["none", null, "inline-block"]}>
              <Separator
                color={ColorPreset.BorderOnLight_03}
                direction="inline-vertical"
                spacing={0.5}
                thickness={1}
              />
            </Visibility>

            <Space layout={["block", null, "inline"]} v={[0.25, null, 0]} />
            <PlainButton onClick={handleUndo} textDecoration="underline">
              <Trans>Undo</Trans>
            </PlainButton>
          </>
        )}
      </Box>
    </Banner>
  );
};
