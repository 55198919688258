import { useUserShowSelf } from "@gocardless/api/dashboard/user";

import { useProposedAccountLinks } from "./useProposedAccountLinks";

import { Redirect, Route } from "src/components/routing";
import { useMultiAccountSessionStorage } from "src/libraries/organisation/multi-accounts/hooks";

interface ProposedAccountLinkWrapperProps {
  children: React.ReactNode;
  bypassChecks?: boolean;
}

export const ProposedAccountLinkWrapper: React.FC<
  ProposedAccountLinkWrapperProps
> = ({ children, bypassChecks }) => {
  const { isLoading: proposedLinksLoading, proposedAccountLinks } =
    useProposedAccountLinks();

  const { data, isLoading: userLoading } = useUserShowSelf();

  const { sessionStorage } = useMultiAccountSessionStorage();

  const bypassProposedLinkChecks =
    bypassChecks || sessionStorage.bypassProposedLinkChecks;

  const proposedAccountLink = proposedAccountLinks[0];

  if (bypassProposedLinkChecks) {
    return <>{children}</>;
  }

  if (proposedLinksLoading || userLoading) {
    return null;
  }

  if (proposedAccountLink?.approving_user_id === data?.users?.id) {
    return <Redirect route={Route.LinkAccountRequest} />;
  }

  return <>{children}</>;
};
